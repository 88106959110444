.site-header {
  border-top-color: hsl(145, 58.9%, 56.1%); }

.post-image {
  margin: 0 -15px 2em; }

.image-credit {
  font-size: 0.7em;
  text-transform: uppercase;
  padding: 0.4em 0.2em;
  text-align: right;

  &, a, a:visited, a:hover {
    color: #bbb; }
  a, a:visited, a:hover {
    text-decoration: none; } }
